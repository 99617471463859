import type { ReactNode, ReactElement } from 'react'
import React, { PureComponent } from 'react'

import { getDataSelector } from '@vfuk/core-base-props'

import * as Styled from './styles/SimpleGrid.style'

import type { SimpleGridProps, LayoutColumn, Layout } from './SimpleGrid.types'

export class SimpleGrid extends PureComponent<SimpleGridProps> {
  private componentName = 'SimpleGrid'

  public static defaultProps: Partial<SimpleGridProps> = {
    spacing: 0,
    justify: 'left',
  }

  public getLayout(index: number): LayoutColumn {
    const layoutProps = this.props.layout
    const layout: LayoutColumn = {}
    if (!layoutProps) return layout

    Object.keys(layoutProps).forEach((breakpoint: keyof Layout) => {
      const value = layoutProps[breakpoint] as number[]
      layout[breakpoint] = value[index % value.length]
    })
    return layout
  }

  public get filteredChildren(): ReactNode[] {
    const filteredChildren: ReactNode[] = []
    React.Children.forEach(this.props.children, (child) => {
      if (child) filteredChildren.push(child)
    })
    return filteredChildren
  }

  public render(): ReactNode {
    return (
      <Styled.SimpleGrid
        id={this.props.id}
        data-component-name={this.componentName}
        data-selector={getDataSelector(this.props.dataSelectorPrefix)}
        justify={this.props.justify!}
        spacing={this.props.spacing!}
        verticalAlign={this.props.verticalAlign!}
        {...this.props.dataAttributes}
      >
        {this.filteredChildren.map((child: ReactNode, index: number): ReactElement | null => {
          return (
            <Styled.SimpleGridColumn
              key={index}
              columns={this.props.columns}
              spacing={this.props.spacing}
              verticalAlign={this.props.verticalAlign!}
              layout={this.getLayout(index)}
              data-selector={getDataSelector(this.props.dataSelectorPrefix, `column-${index}`)}
            >
              {child}
            </Styled.SimpleGridColumn>
          )
        })}
      </Styled.SimpleGrid>
    )
  }
}

export default SimpleGrid

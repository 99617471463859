import type { FlattenSimpleInterpolation } from 'styled-components'
import styled, { css } from 'styled-components'

import Interaction from '@vfuk/core-interaction'
import { spacing } from '@vfuk/core-mixins'

import type { StyledListItemProps } from './ListItem.style.types'

export const InteractionListItem = styled(Interaction)(
  (): FlattenSimpleInterpolation => css`
    width: 100%;
    text-align: left;
  `,
)

export const ListItem = styled.li(
  (props: StyledListItemProps): FlattenSimpleInterpolation => css`
    ${props.listStyle === 'none' &&
    css`
      list-style: none;
    `}

    ${props.listType === 'unordered' &&
    props.listStyle === 'default' &&
    css`
      list-style: disc;
      ${spacing('margin-left', 6)};
    `}

  ${props.listType === 'ordered' &&
    props.listStyle === 'default' &&
    css`
      list-style: decimal;
      ${spacing('margin-left', 6)};
      ${spacing('padding-left', 2)};
    `}
  `,
)
